import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { Fetch_URL } from "../../../../Context/Context";

const containerStyle = {
  width: "96%",
  height: "500px",
  marginLeft: "7px",
  marginTop: "5px",
  borderRadius: "7px",
};

const center = {
  lat: 28.8386,
  lng: 78.7733,
};

export default function Map() {
  const navigate = useNavigate();
  const [mapinfo, setMapinfo] = useState([]);
  const [map, setMap] = useState([]);

  const mapData = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_api.php`, {
        aksi: "proses_fetch_hotel_details",
        hotel_id: window.$loginData?.hotel_id,
        owner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "resp data");
        setMapinfo(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCqtFzUjZSX5DUMbbDGnmc0S9FJF3-DiJE",
  });

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  });

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  useEffect(() => {
    mapData();
  }, []);

  return (
    <>
      <div>
        <div className="headingLine">
          <div
            className="backIcon"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="header">
            <h2>Property Details</h2>
          </div>
        </div>
        <hr />
        {mapinfo.map((item) => {
          return (
            <>
              <div className="step1Info">
                <h3>{item?.hotel_name}</h3>
              </div>
              <div className="mapPin">
                <h4>Location Pin</h4>
                <h5>https://google.co.in/maps?q=234777777, 567778</h5>
              </div>
              <div>
                {isLoaded ? (
                  <>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={10}
                      onLoad={onLoad}
                      // onUnmount={onUnmount}
                    >
                      <Marker draggable={true} position={center} />
                    </GoogleMap>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="mapPin">
                <h4>Your Address</h4>
                <h5>
                  {item?.hotel_address}, {item?.hotel_city}, {item?.hotel_state}
                  , {item?.hotel_pincode}
                </h5>
              </div>
            </>
          );
        })}
        <hr />
      </div>
    </>
  );
}

/* Map CSS */

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Fetch_URL } from "../../../../../Context/Context";

export default function Bank() {
  const navigate = useNavigate();
  const [bankdetail, setBankdetail] = useState([]);

  const bankInfo = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_api.php`, {
        aksi: "proses_fetch_hotel_details",
        hotel_id: window.$loginData?.hotel_id,
        owner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "resp data");
        setBankdetail(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    bankInfo();
  }, []);

  return (
    <>
      <div className="fourHead">
        <div className="fourInfo">
          <div
            className="backIcon"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="fourText">
            <h2>Step 3</h2>
          </div>
        </div>
        {/* <div className="fourIcon">
          <i className="fa fa-user"></i>
          <i className="fa fa-question-circle"></i>
        </div> */}
      </div>

      <div className="step1Info">
        <h3>Account Details</h3>
        <p>
          Payments from Go Partner will be transferred to the added account
          below.
        </p>
      </div>
      {bankdetail?.map((item) => {
        return (
          <>
            <div className="step1Input">
              <label>Account Holder</label>
              <input
                type="text"
                placeholder={item?.account_holder_name}
                disabled
              />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>Account Number</label>
              <input type="text" placeholder={item?.account_number} disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>Bank Name</label>
              <input type="text" placeholder={item?.bank_name} disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>IFSC Code</label>
              <input type="text" placeholder={item?.ifsc_code} disabled />
              <i className="fa fa-check"></i>
            </div>
          </>
        );
      })}
    </>
  );
}

/* Personal CSS Used in Bank CSS*/

import axios from "axios";
import { useEffect, useState } from "react";
import { Fetch_URL } from "../../../Context/Context";
// mui
import { ColorRing } from "react-loader-spinner";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import * as React from "react";
// import Switch from "@mui/material/Switch";

export default function UpcomingBook() {
  // const label = { inputProps: { "aria-label": "Switch demo" } };

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (item) => (event, isExpanded) => {
    setExpanded(isExpanded ? item : false);
  };

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [checkedin, setCheckedin] = useState(false);
  const [cancel, setCancel] = useState(false);
  // const [payment, setPayment] = useState(false);

  const upcoming = () => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_fetchbookingbyStatus",
        booking_status: "upcomming",
        hotel_id: window.$loginData?.hotel_id,
      })
      .then((resp) => {
        console.log(resp, "upcoming booking resp data");
        setData(resp.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const paymentStatus = (Id) => {
  //   axios
  //     .post(`${Fetch_URL}/API/booking_api.php`, {
  //       aksi: "proses_chnagebooking_amountstatus",
  //       amount_status: "Payment Collected",
  //       b_id: Id,
  //     })
  //     .then((resp) => {
  //       console.log(resp, "payment resp data");
  //       setPayment(resp.data.success);
  //       toast.success("Payment-Collected", {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const checkinStatus = (Id) => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_chnagebookingstatus",
        booking_status: "Inhouse",
        b_id: Id,
      })
      .then((resp) => {
        console.log(resp, "checkin resp data");
        resp.data.success && setCheckedin(resp.data.success);
        toast.success("Checked-In", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        upcoming();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelStatus = (Id) => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_chnagebookingstatus",
        booking_status: "Cancelled",
        b_id: Id,
      })
      .then((resp) => {
        console.log(resp, "cancel resp data");
        resp.data.success && setCancel(resp.data.success);
        toast.success("Cancelled Booking", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        upcoming();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    upcoming();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="bookDataCont">
        {loader ? (
          <>
            <div className="loader">
              <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["green", "green", "green", "green", "green"]}
              />
            </div>
          </>
        ) : (
          <>
            {data?.length === 0 || data === null ? (
              <>
                <div className="inhouseImg">
                  <img
                    src="woman-looking-monthly-calendar-menstruation-260nw-1687894090.webp"
                    alt=""
                  />
                  <h2>No booking, yet!</h2>
                  <h4>You don't have any upcoming booking right now.</h4>
                </div>
              </>
            ) : (
              <>
                <div className="upcomingHead">
                  <h4>Arriving Today ({data?.length})</h4>
                </div>
                {data?.map((item) => {
                  return (
                    <>
                      <Accordion
                        expanded={expanded === item}
                        onChange={handleChange(item)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            {item.user_name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="bookDetail">
                              <div className="bookText">
                                <h4>{item.user_name}</h4>
                                <h4>
                                  <i className="fa fa-rupee"></i>
                                  {item.amount}
                                </h4>
                              </div>
                              <div className="bookText">
                                <div className="bookId">
                                  <h5>#{item.booking_id}</h5>
                                  <h5>- {item.booking_date}</h5>
                                </div>
                                {/* <div className="bookBtn">
                                  <button
                                    onClick={() => {
                                      paymentStatus(item.id);
                                    }}
                                  >
                                    {item.amount_status}
                                  </button>
                                </div> */}
                              </div>
                              <div className="bookId">
                                <h5>Mobile-No:-</h5>
                                <h5>{item.user_mobile}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Check-In Time:-</h5>
                                <h5>{item.checkintime}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Paid Amount:-</h5>
                                <h5>{item.razr_amt}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Rest Amount (Pay at Hotel):-</h5>
                                <h5>{item.restamount}</h5>
                              </div>
                              <div className="bookText">
                                <div className="bookId">
                                  <h5>Check-In:-</h5>
                                  <h5>{item.check_id}</h5>
                                </div>
                                <div className="bookBtn">
                                  <button
                                    onClick={() => {
                                      checkinStatus(item.id);
                                    }}
                                  >
                                    {checkedin ? "Checked-In" : "Check-In"}
                                  </button>
                                </div>
                              </div>
                              <div className="bookText">
                                <div className="bookId">
                                  <h5>Check-Out:-</h5>
                                  <h5>{item.check_out}</h5>
                                </div>
                                <div className="bookBtn">
                                  <button
                                    onClick={() => {
                                      cancelStatus(item.id);
                                    }}
                                  >
                                    {cancel ? "Cancelled" : "Cancel"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>

      {/* <div className="upcomingText">
          <h5>Earn More Revenue Month</h5>
          <h6>Popular hotels in your area are using the below programs</h6>
        </div>

        <div className="upcomingAllData">
          <div className="upcomingData">
            <div className="upcomingInfo">
              <h4>OTA REVMAX</h4>
              <h5>
                Get upto 5x more visibility, 30% more revenue from OTA's
                <span> Know More</span>
              </h5>
            </div>
            <div className="upcomingSwitch">
              <Switch {...label} />
            </div>
          </div>
          <div className="upcomingHr">
            <hr />
          </div>

          <div className="upcomingData">
            <div className="upcomingInfo">
              <h4>OTA REVMAX</h4>
              <h5>
                Get upto 5x more visibility, 30% more revenue from OTA's
                <span> Know More</span>
              </h5>
            </div>
            <div className="upcomingSwitch">
              <Switch {...label} />
            </div>
          </div>
          <div className="upcomingHr">
            <hr />
          </div>

          <div className="upcomingData">
            <div className="upcomingInfo">
              <h4>OTA REVMAX</h4>
              <h5>
                Get upto 5x more visibility, 30% more revenue from OTA's
                <span> Know More</span>
              </h5>
            </div>
            <div className="upcomingSwitch">
              <Switch {...label} />
            </div>
          </div>
        </div> */}
    </>
  );
}

// UpcomingBook CSS

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Fetch_URL } from "../../../../../Context/Context";

export default function Gstn() {
  const navigate = useNavigate();
  const [detail, setDetail] = useState([]);

  const getInfo = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_api.php`, {
        aksi: "proses_fetch_hotel_details",
        hotel_id: window.$loginData?.hotel_id,
        owner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "resp data");
        setDetail(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <div className="fourHead">
        <div className="fourInfo">
          <div
            className="backIcon"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="fourText">
            <h2>Step 2</h2>
          </div>
        </div>
        {/* <div className="fourIcon">
          <i className="fa fa-user"></i>
          <i className="fa fa-question-circle"></i>
        </div> */}
      </div>

      <div className="step1Info">
        <h3>GSTN & PAN Details</h3>
        <p>
          You have added a personal PAN number, TDS will be deducted according
          to applicable laws & rules for individuals. You have not added GSTIN
          number.
        </p>
      </div>
      {detail?.map((item) => {
        return (
          <>
            <div className="step1Input">
              <label>Business Entity</label>
              <input type="text" placeholder={item?.business_ertity} disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>Name on PAN</label>
              <input type="text" placeholder={item?.pan_holder_name} disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>PAN Number</label>
              <input type="text" placeholder={item?.pan_number} disabled />
              <i className="fa fa-check"></i>
            </div>
          </>
        );
      })}
    </>
  );
}

/* Personal CSS Used in Gstn CSS*/

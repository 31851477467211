import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetch_URL } from "../../../Context/Context";

export default function SignUpDetails() {
  const location = useLocation();
  const stateData = location.state;
  // console.log(stateData, "stateData");

  const navigate = useNavigate();
  const [name, setName] = useState([]);
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);

  const signup = () => {
    name?.length > 0 && email?.length > 0 && password?.length > 0
      ? axios
          .post(`${Fetch_URL}/API/hotel_login.php`, {
            aksi: "proses_personaldetails",
            name: name,
            email: email,
            mobile: stateData,
            password: password,
          })
          .then((resp) => {
            console.log(resp, "signup resp data");
            resp?.data?.success
              ? toast.success("Signup Successfully", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) &&
                setTimeout(() => {
                  navigate("/signin");
                }, 2000)
              : toast.error("Signup not Successfull", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Fill All Details", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="loginCont">
        <div className="loginHead">
          <div className="loginText">
            <h2>CO PAYGOO</h2>
          </div>
          <div className="lang">
            <i className="fa fa-globe"></i>
            <h5>English</h5>
          </div>
        </div>
        <div className="loginBottom">
          <div className="loginInfo">
            <h2>Welcome to CO PAYGOO</h2>
            <h4>Enter Your Registration Details</h4>
            <h3>+91{stateData}</h3>
          </div>
          <div className="loginBox">
            <div className="loginData">
              <label>Name</label>
              <div className="loginInput">
                <input
                  type="text"
                  placeholder="Enter Your Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="loginData">
              <label>Email Id</label>
              <div className="loginInput">
                <input
                  type="text"
                  placeholder="Enter Your Email Id"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="loginData">
              <label>Password</label>
              <div className="loginInput">
                <input
                  type="text"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter Your Password"
                />
              </div>
            </div>
            <div></div>
          </div>
          <div className="signupBtn" onClick={signup}>
            <button>Sign Up</button>
          </div>
          <div className="loginTerms">
            <h6>
              By logging into the account you are agreeing with our
              <span> Terms of use </span>and <span>Privacy policy</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Fetch_URL } from "../../../Context/Context";

export default function Profile() {
  const navigate = useNavigate();
  const [hoteldata, setHoteldata] = useState([]);
  const [ownerdata, setOwnerdata] = useState([]);

  const hotelInfo = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_api.php`, {
        aksi: "proses_fetch_hotel_details",
        hotel_id: window.$loginData?.hotel_id,
        owner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "hotel resp data");
        setHoteldata(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ownerDetails = () => {
    axios
      .post(`${Fetch_URL}/API/owner_api.php`, {
        aksi: "proses_fetch_owner_details",
        owner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "ownerdata resp data");
        setOwnerdata(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function logout() {
    localStorage.removeItem("loginData");
    navigate("/signin");
  }

  useEffect(() => {
    hotelInfo();
    ownerDetails();
  }, []);

  return (
    <>
      <div className="fourHead">
        <div className="fourInfo">
          <div
            className="backIcon"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="fourText">
            <h2>Profile</h2>
          </div>
        </div>
        <div className="fourIcon" onClick={logout}>
          <h5>Logout</h5>
        </div>
      </div>

      <div className="profileCont">
        {hoteldata?.map((item) => {
          return (
            <>
              <div className="profileHead">
                <img src="3434330.jpg" alt="" />
                <h3>{item?.hotel_name}</h3>
                <h4>
                  {item?.hotel_city}, {item?.hotel_pincode}
                </h4>
              </div>

              <div className="profileText">
                <h3>Your Bank Accounts</h3>
              </div>
              <div className="profileInfo">
                <h3>{item?.account_holder_name}</h3>
                <h4>{item?.bank_name}</h4>
                <h4>{item?.account_number}</h4>
                <h4>{item?.account_holder_name}</h4>
                <h4>{item?.ifsc_code}</h4>
              </div>
            </>
          );
        })}
        {ownerdata?.map((item) => {
          return (
            <>
              <div className="profileText">
                <h3>Your Details</h3>
              </div>
              <div className="profileInfo">
                <h3>
                  <i className="fa fa-user"></i>
                  {item?.owner_name}
                </h3>
                <h4>
                  <i className="fa fa-envelope"></i>
                  {item?.owner_email}
                </h4>
                <h4>
                  <i className="fa fa-phone"></i>
                  {item?.owner_mobile}
                </h4>
              </div>

              <div className="profileText">
                <h3>Legal Entity Details</h3>
              </div>
              <div className="profileInfo">
                <h3>
                  <i className="fa fa-user"></i>
                  {item?.owner_name}
                </h3>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

/* Profile CSS */

import SpeedIcon from "@mui/icons-material/Speed";
// import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
// import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
// import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
// import PlaylistAddCheckCircleOutlinedIcon from "@mui/icons-material/PlaylistAddCheckCircleOutlined";
// import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
// import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
// import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
// import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link, useNavigate } from "react-router-dom";

export default function More() {
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("loginData");
    navigate("/signin");
  }

  return (
    <>
      <div className="moreCont">
        <Link to="/mygopartner">
          <div className="moreBar">
            <SpeedIcon />
            <h3>My PAYGOO</h3>
          </div>
        </Link>
        <Link to="/policy">
          <div className="moreBar">
            <FeedOutlinedIcon />
            <h3>Policies/Contracts</h3>
          </div>
        </Link>
        {/* <div className="moreBar">
          <AccountBalanceWalletOutlinedIcon />
          <h3>Secure</h3>
        </div> */}
        {/* <div className="moreBar">
          <AssignmentTurnedInOutlinedIcon />
          <h3>Check In %</h3>
        </div> */}
        {/* <div className="moreBar">
          <Inventory2OutlinedIcon />
          <h3>Inventory</h3>
        </div> */}
        {/* <div className="moreBar">
          <PlaylistAddCheckCircleOutlinedIcon />
          <h3>Business Reports</h3>
        </div> */}
        {/* <div className="moreBar">
          <HeadphonesOutlinedIcon />
          <h3>Help/Raise a Ticket</h3>
        </div> */}
        {/* <div className="moreBar">
          <NoteAltOutlinedIcon />
          <h3>Share Feedback</h3>
        </div> */}
        {/* <div className="moreBar">
          <AddBusinessOutlinedIcon />
          <h3>Refer & Earn</h3>
        </div> */}
        {/* <div className="moreBar">
          <PersonOutlineOutlinedIcon />
          <h3>User Management</h3>
        </div> */}
        <Link to="/profile">
          <div className="moreBar">
            <PersonAddAltOutlinedIcon />
            <h3>Manage Profile</h3>
          </div>
        </Link>
        <div className="moreBar" onClick={logout}>
          <LogoutOutlinedIcon />
          <h3>Log Out</h3>
        </div>
      </div>
    </>
  );
}

/* More CSS */

import { Link } from "react-router-dom";
import More from "../More/More";
// mui
import * as React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

export default function BottomNav() {
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "bottom" ? "auto" : 400, marginBottom: "20px" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="bottomInfo">
        <i className="fa fa-close"></i>
      </div>
      <div>
        <More />
      </div>
      <Divider />
    </Box>
  );

  return (
    <>
      <div className="bottomNav">
        <Link to="/">
          <div className="botInfo">
            <div className="botImg">
              <i className="fa fa-line-chart"></i>
            </div>
            <h5>Growth</h5>
          </div>
        </Link>
        <Link to="/bookings">
          <div className="botInfo">
            <div className="botImg">
              <i className="fa fa-vcard"></i>
            </div>
            <h5>Bookings</h5>
          </div>
        </Link>
        {/* <Link to="/pricing">
          <div className="botInfo">
            <div className="botImg">
              <i className="fa fa-money"></i>
            </div>
            <h5>Pricing</h5>
          </div>
        </Link> */}
        {/* <Link to="/earnings">
          <div className="botInfo">
            <div className="botImg">
              <i className="fa fa-credit-card"></i>
            </div>
            <h5>Earnings</h5>
          </div>
        </Link> */}
        <div className="botInfo">
          <div className="botImg">
            <i className="fa fa-tasks"></i>
          </div>
          {["bottom"].map((anchor) => (
            <div key={anchor}>
              <h5 onClick={toggleDrawer(anchor, true)}>More</h5>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import BottomNav from "../../BottomNavigate/BottomNav";

export default function Policy() {
  return (
    <>
      <BottomNav />
      <div className="navText">
        <h2>Products, Policies & SOP's</h2>
      </div>
      <div className="policyCont">
        <div className="policyText">
          <h3>Financial Assistance Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>PAYGOO Brand Guidelines</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>PAYGOO Digital Audit Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>PAYGOO Walk-In Contribution Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>PAYGOO Wizard Partnership Network Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>PAYGOO Quality Score (3C Score)</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>Smart Device Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>Transformation Investment Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
        <div className="policyText">
          <h3>PAYGOO Safety & Security Policy</h3>
          <i className="fa fa-angle-right"></i>
        </div>
      </div>
    </>
  );
}

/* Policy CSS */

import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fetch_URL } from "../../../Context/Context";

export default function SignUp() {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState([]);
  const [otp, setOtp] = useState([]);
  const [otpform, setOtpform] = useState(false);

  const getOtp = () => {
    mobile?.length === 10
      ? axios
          .post(`${Fetch_URL}/API/hotel_login.php`, {
            aksi: "proses_register",
            mobile: mobile,
          })
          .then((resp) => {
            console.log(resp, "getOtp resp data");
            resp?.data?.success
              ? toast.success("OTP has been sent", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) && setOtpform(true)
              : toast.error("Mobile Number Already Registered", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) && setOtpform(false);
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Mobile number not Valid", {
          position: toast.POSITION.BOTTOM_LEFT,
        }) && setOtpform(false);
  };

  const verifyOtp = () => {
    otp?.length > 0
      ? axios
          .post(`${Fetch_URL}/API/hotel_login.php`, {
            aksi: "proses_verifyotp",
            otp: otp,
            mobileno: mobile,
          })
          .then((resp) => {
            console.log(resp, "verify resp data");
            resp.data.success
              ? navigate("/signupdetails", { state: mobile })
              : toast.error("Wrong OTP", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Enter OTP", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="loginCont">
        <div className="loginHead">
          <div className="loginText">
            <h2>CO PAYGOO</h2>
          </div>
          <div className="lang">
            <i className="fa fa-globe"></i>
            <h5>English</h5>
          </div>
        </div>
        <div className="loginBottom">
          <div className="loginInfo">
            <h2>Welcome to CO PAYGOO</h2>
            <h4>
              Enter the registered email address or phone number associated with
              us
            </h4>
          </div>
          <div className="loginBox">
            <div className="loginData">
              <label>Phone Number</label>
              <div className="loginInput">
                <input
                  type="number"
                  placeholder="Enter Your Phone No."
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="signupBtn" onClick={getOtp}>
            <button>Get OTP</button>
          </div>
          {otpform ? (
            <>
              <div className="otpData">
                <h4>+91{mobile}</h4>
              </div>
              <div className="otpInput2">
                <input
                  type="text"
                  placeholder="Enter OTP"
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              </div>
              <div className="passLink" onClick={getOtp}>
                <h5>Resend Code</h5>
              </div>
              <div className="loginBtn" onClick={verifyOtp}>
                <button>Verify OTP</button>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="loginTerms">
            <h6>
              By logging into the account you are agreeing with our
              <span> Terms of use </span>and <span>Privacy policy</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

import BottomNav from "../BottomNavigate/BottomNav";
import UpcomingBook from "./UpcomingBook/UpcomingBook";
import InhouseBook from "./InhouseBook/InhouseBook";
import CompletedBook from "./CompletedBook/CompletedBook";
// import { Link, useNavigate } from "react-router-dom";

// material ui
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CancelledBook from "./CancelledBook/CancelledBook";
// import SearchIcon from "@mui/icons-material/Search";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import Divider from "@mui/material/Divider";
// import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Booking() {
  // const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [state, setState] = React.useState({
  //   bottom: false,
  // });

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: anchor === "bottom" ? "auto" : 250, marginBottom: "20px" }}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <div
  //       className="bottomInfo"
  //       onClick={() => {
  //         navigate(-1);
  //       }}
  //     >
  //       <i className="fa fa-close"></i>
  //     </div>
  //     <div>
  //       <div className="moreBar">
  //         <FeedOutlinedIcon />
  //         <h3>PBOI requests</h3>
  //       </div>
  //     </div>
  //     <Divider />
  //   </Box>
  // );

  return (
    <>
      <div className="bookCont">
        <BottomNav />
        <div className="navbody">
          <div className="navbar">
            <div className="navText">
              <h2>Bookings</h2>
            </div>
            {/* <div className="navIcon"> */}
            {/* <div className="navIconImg2">
                <Link to="/searchbar">
                  <SearchIcon />
                </Link>
              </div> */}
            {/* {["bottom"]?.map((anchor) => (
                <div
                  className="navIconImg2"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <MoreVertOutlinedIcon />
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </div>
              ))} */}
            {/* </div> */}
          </div>
        </div>
        <Box
          sx={{
            width: "100%",
            borderTop: "0.5px solid gray",
            marginTop: "2px",
          }}
        >
          <div className="tabPosition">
            <Box
              sx={{
                maxWidth: { xs: 400, sm: 480 },
                bgcolor: "background.paper",
              }}
            >
              {/* <div className="bookIcon">
                <FilterAltOutlinedIcon />
              </div> */}
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable prevent tabs example"
              >
                <Tab label="Upcoming" />
                <Tab label="In-house" />
                <Tab label="Completed" />
                <Tab label="Cancelled" />
              </Tabs>
            </Box>
          </div>
          <TabPanel value={value} index={0}>
            <UpcomingBook />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InhouseBook />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <CompletedBook />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CancelledBook />
          </TabPanel>
        </Box>
        {/* <div className="addBooking">
          <Link to="/addbooking">
            <i className="fa fa-plus"></i>
          </Link>
        </div> */}
      </div>
    </>
  );
}

import { Link, useNavigate } from "react-router-dom";

export default function Kyc() {
  const navigate = useNavigate();

  return (
    <>
      <div className="headingLine">
        <div
          className="backIcon"
          onClick={() => {
            navigate(-1);
          }}
        >
          <i className="fa fa-arrow-left"></i>
        </div>
        <div className="header">
          <h2>KYC</h2>
        </div>
      </div>
      <div className="myGotext2">
        <h3>3 out of 3 Completed</h3>
      </div>
      <div className="kycBox">
        <Link to="/personal">
          <div className="myGoinfo2">
            <i className="fa fa-user"></i>
            <h3>Step 1 : Personal details</h3>
            <i className="fa fa-angle-right"></i>
          </div>
        </Link>
        <Link to="/gstn">
          <div className="myGoinfo2">
            <i className="fa fa-credit-card"></i>
            <h3>Step 2 : GSTN & PAN Card</h3>
            <i className="fa fa-angle-right"></i>
          </div>
        </Link>
        <Link to="/bank">
          <div className="myGoinfo2">
            <i className="fa fa-bank"></i>
            <h3>Step 3 : Bank Account Details</h3>
            <i className="fa fa-angle-right"></i>
          </div>
        </Link>
      </div>
    </>
  );
}

/* Kyc CSS */

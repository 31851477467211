import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Growth from "../Components/Growth/Growth";

function Home() {
  const navigate = useNavigate();

  window.$loginValue = localStorage.getItem("loginData");
  window.$loginData = JSON.parse(window.$loginValue);
  // console.log(window.$loginData, "loginAllData");

  useEffect(() => {
    if (!localStorage.getItem("loginData")) {
      navigate("/signin");
    }
  }, []);

  return (
    <>
      <div className="App">
        <Growth />
      </div>
    </>
  );
}

export default Home;

import BottomNav from "../BottomNavigate/BottomNav";
import axios from "axios";
// material ui
import * as React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Fetch_URL } from "../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import CircularProgress from "@mui/material/CircularProgress";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import Divider from "@mui/material/Divider";

// function CircularProgressWithLabel(props) {
//   return (
//     <Box sx={{ position: "relative", display: "inline-flex" }}>
//       <CircularProgress variant="determinate" {...props} />
//       <Box
//         sx={{
//           top: 0,
//           left: 0,
//           bottom: 0,
//           right: 0,
//           position: "absolute",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <Typography variant="caption" component="div" color="black">
//           {`${Math.round(props.value)}%`}
//         </Typography>
//       </Box>
//     </Box>
//   );
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Growth() {
  const [value, setValue] = React.useState(0);
  const [upcominginfo, setUpcominginfo] = React.useState([]);
  const [inhouseinfo, setInhouseinfo] = React.useState([]);
  const [compinfo, setCompinfo] = React.useState([]);
  // const [amount, setAmount] = React.useState([]);
  const [moneydata, setMoneydata] = React.useState([]);
  const [month, setMonth] = React.useState([]);
  const [payamount, setPayamount] = React.useState([]);

  window.$loginData = JSON.parse(window.$loginValue);
  console.log(window.$loginData, "loginAllData");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const moneyInfo = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_login.php`, {
        aksi: "proses_fetchAccountstatus",
        id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "moneyInfo resp data");
        setMoneydata(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const recharge = () => {
    axios
      .post(`${Fetch_URL}/API/partner_account.php`, {
        aksi: "orderGenerate",
        onlineamt: payamount,
        partner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "recharge resp data");
        handleRazorpay(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRazorpay = (data) => {
    const option = {
      key: "rzp_test_7XRbf19kGvG9nz",
      amount: data.amount,
      currency: data.currency,
      order_id: data.id,
      name: "Paygoo",
      description: "Paygoo Transaction",
      handler: function (response) {
        console.log(response, "razorpay response");
        Object.keys(response)?.length > 0 && postRazorinfo(response);
      },
    };

    const razor = new window.Razorpay(option);
    razor.open();
  };

  const postRazorinfo = (responseData) => {
    axios
      .post(`${Fetch_URL}/API/partner_account.php`, {
        aksi: "proses_paymentcheck",
        razorpay_order_id: responseData?.razorpay_order_id,
        razorpay_payment_id: responseData?.razorpay_payment_id,
        razorpay_signature: responseData?.razorpay_signature,
        month: month,
        partner_id: window.$loginData?.id,
        expired_date: moneydata?.expired_date,
      })
      .then((resp) => {
        console.log(resp, "postRazorinfo resp data");
        resp?.data?.success && moneyInfo();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const [state, setState] = React.useState({
  //   bottom: false,
  // });

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (
  //     event &&
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };

  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: anchor === "bottom" ? "auto" : 250 }}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <div className="bottomInfo">
  //       <i className="fa fa-close"></i>
  //       <h3>My Properties</h3>
  //     </div>
  //     <div className="bottomCard">
  //       <div className="bottomCardImg">
  //         <img src="3434330.jpg" alt="" />
  //       </div>
  //       <div className="bottomText">
  //         <h3>CO OYO Home 888998 Shashi Paradise (CNS002)</h3>
  //         <h4>Chandausi, India</h4>
  //       </div>
  //     </div>
  //     <Divider />
  //   </Box>
  // );

  const upcomingBook = () => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_fetchbookingbyStatus",
        booking_status: "Upcomming",
        hotel_id: window.$loginData?.hotel_id,
      })
      .then((resp) => {
        console.log(resp, "upcomingBook resp data");
        setUpcominginfo(resp?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const inhouseBook = () => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_fetchbookingbyStatus",
        booking_status: "Inhouse",
        hotel_id: window.$loginData?.hotel_id,
      })
      .then((resp) => {
        console.log(resp, "inhouseBook resp data");
        setInhouseinfo(resp?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const compBook = () => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_fetchbookingbyStatus",
        booking_status: "Completed",
        hotel_id: window.$loginData?.hotel_id,
      })
      .then((resp) => {
        console.log(resp, "compBook resp data");
        setCompinfo(resp?.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    upcomingBook();
    inhouseBook();
    compBook();
    moneyInfo();
  }, []);

  const findMoney = () => {
    if (month === "2") {
      setPayamount(month * moneydata?.amount);
    } else if (month === "3") {
      let discount10 = (month * moneydata?.amount * 10) / 100;
      setPayamount(month * moneydata?.amount - discount10);
    } else if (month === "6") {
      let discount15 = (month * moneydata?.amount * 15) / 100;
      setPayamount(month * moneydata?.amount - discount15);
    } else if (month === "12") {
      let discount20 = (month * moneydata?.amount * 20) / 100;
      setPayamount(month * moneydata?.amount - discount20);
    } else if (month === "1") {
      setPayamount(month * moneydata?.amount);
    } else {
      setPayamount(month * moneydata?.amount);
    }
  };

  const clickRech = () => {
    if (month?.length > 0) {
      recharge();
    } else {
      toast.error("Please Select Month", {
        position: toast.POSITION.BOTTOM_LEFT,
        id: "err",
      });
    }
  };

  console.log(month, "month");
  console.log(payamount, "sksjksjk");

  React.useEffect(() => {
    findMoney();
  }, [month]);

  return (
    <>
      <ToastContainer />
      <div>
        <BottomNav />
        <div className="navbody">
          <div className="navbar">
            <div className="navText">
              {/* {["bottom"].map((anchor) => (
                <div key={anchor}> */}
              <h2
              // onClick={toggleDrawer(anchor, true)}
              >
                {window.$loginData?.hotel_name}
                {/* <i
                      style={{ marginLeft: "10px", fontWeight: "800" }}
                      className="fa fa-angle-down"
                    ></i> */}
              </h2>
              {/* <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </div>
                  ))} */}
            </div>
            <div className="navIcon">
              {/* <div className="navIconImg">
                <Link to="/searchbar">
                  <i className="fa fa-search"></i>
                </Link>
              </div> */}
              <div className="navIconImg">
                <a href="tel: +917837837179">
                  <i className="fa fa-phone"></i>
                </a>
              </div>
              {/* <div className="navIconImg">
                <Link to="/notify">
                  <i className="fa fa-bell"></i>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
        <div className="topHr">
          <hr />
        </div>

        {/* <div className="topCardData">
          <div className="topHead">
            <div className="topHeadInfo">
              <h2>
                Rewards<span>New</span>
              </h2>
              <h5>Enroll to unlock rewards</h5>
            </div>
            <div className="topHeadIcon">
              <i className="fa fa-gift"></i>
            </div>
          </div>
          <div className="topCard">
            <div className="topCardInfo">
              <h6>Check-in % Target</h6>
              <h6>
                <i className="fa fa-clock-o"></i>Ends in 46 Days
              </h6>
            </div>
            <div className="topCardText">
              <h5>
                Maintain Target Check-in% to get Rs.250 Beneficiary Price, 3%
                Cashback on OYO...
              </h5>
              <button>Enroll Now</button>
            </div>
          </div>
        </div>
        <Box
          sx={{
            maxWidth: { xs: 400, sm: 480 },
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab label="Last 30 days" />
            <Tab label="Last 7 days" />
            <Tab label="Today" />
            <Tab label="This Month" />
          </Tabs>
        </Box> */}
        <TabPanel value={value} index={0}>
          {/* <div className="homeAllInfo">
            <div className="allMiniCards">
              <div className="miniCard">
                <div className="miniCardInfo">
                  <h4>Occupancy</h4>
                  <i className="fa fa-angle-right"></i>
                </div>
                <div className="miniCardData">
                  <h3>100%</h3>
                </div>
                <div className="miniCardData2">
                  <h5>Areauydgyud rfhu best5aghsvhhh</h5>
                </div>
              </div>

              <div className="miniCard">
                <div className="miniCardInfo">
                  <h4>Occupancy</h4>
                  <i className="fa fa-angle-right"></i>
                </div>
                <div className="miniCardData">
                  <h3>100%</h3>
                </div>
                <div className="miniCardData2">
                  <h5>Areauydgyud rfhu best5aghsvhhh</h5>
                </div>
              </div>

              <div className="miniCard">
                <div className="miniCardInfo">
                  <h4>Occupancy</h4>
                  <i className="fa fa-angle-right"></i>
                </div>
                <div className="miniCardData">
                  <h3>100%</h3>
                </div>
                <div className="miniCardData2">
                  <h5>Areauydgyud rfhu best5aghsvhhh</h5>
                </div>
              </div>
            </div>

            <div className="activeHead">
              <h2>
                Check-in% <span>i</span>
              </h2>
            </div>
            <div className="checkinfilter">
              <div className="checkfilter">
                <h4>All</h4>
              </div>
              <div className="checkfilter">
                <h4>Pay at Hotel</h4>
              </div>
              <div className="checkfilter">
                <h4>Prepaid</h4>
              </div>
            </div>
            <div className="checkDetail">
              <div className="checkInfo">
                <div className="checkIcon">
                  <i className="fa fa-building"></i>
                </div>
                <div className="checkHead">
                  <h5>Your hotel</h5>
                  <h4>-%</h4>
                </div>
              </div>
              <div className="checkText">
                <h4>Did you know?</h4>
                <h5>Improve check-in% to earn more</h5>
              </div>
              <div className="checkBtn">
                <button>View Details</button>
              </div>
            </div>

            <div className="activeHead">
              <h2>Secure Wallet</h2>
            </div>
            <div className="walletCard">
              <div className="walletText">
                <h4>
                  <i className="fa fa-rupee"></i>0
                </h4>
                <i className="fa fa-angle-right"></i>
              </div>
              <div className="walletText2">
                <h5>Initial Recharge Pending!</h5>
              </div>
              <div className="walletInput">
                <input
                  type="text"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <button onClick={recharge}>Recharge</button>
              </div>
            </div>

            <div className="guestCard">
              <div className="guestText">
                <h4>Guest Experience</h4>
              </div>
              <div className="guestAllRate">
                <div className="guestRate">
                  <h5>PAYGOO Ratings</h5>
                  <h5>
                    <i className="fa fa-star"></i>0.0/5
                  </h5>
                </div>
                <div className="guestRate">
                  <h5>Check-in Ratings</h5>
                  <h5>
                    <i className="fa fa-star"></i>0.0/5
                  </h5>
                </div>
              </div>
              <div className="guestReview">
                <h6>0 Ratings- 0 Reviews</h6>
              </div>
              <div className="rateBtn">
                <button>Improve Ratings</button>
              </div>
            </div>
          </div>

          <div className="allActiveCards">
            <div className="activeHead">
              <h2>Top 5 recommandations for you</h2>
            </div>
            <div className="activeCards">
              <div className="activeCard">
                <div className="activeText">
                  <h2>CO PAYGOO</h2>
                  <i className="fa fa-angle-right"></i>
                </div>
                <div className="activeText2">
                  <h3>Get 2x repeat business from your high paying guests</h3>
                  <h5>Be a top performer on OTA's</h5>
                </div>
                <div className="activeTag">
                  <i className="fa fa-money"></i>
                  <h5>Earn up to 30% more revenue</h5>
                </div>
                <div className="activeBtn1">
                  <button>Activate Now</button>
                </div>
              </div>

              <div className="activeCard">
                <div className="activeText">
                  <h2>CO PAYGOO</h2>
                  <i className="fa fa-angle-right"></i>
                </div>
                <div className="activeText2">
                  <h3>Get 2x repeat business from your high paying guests</h3>
                  <h5>Be a top performer on OTA's</h5>
                </div>
                <div className="activeTag">
                  <i className="fa fa-money"></i>
                  <h5>Earn up to 30% more revenue</h5>
                </div>
                <div className="activeBtn1">
                  <button>Activate Now</button>
                </div>
              </div>
            </div>
            <div className="viewAll">
              <Link to="/allrecommend">
                <h5>
                  View All Recommandations<i className="fa fa-angle-right"></i>
                </h5>
              </Link>
            </div>
          </div>

          <div className="homeAllInfo2">
            <div className="programCard">
              <div className="activeHead">
                <h2>You have 1 active programs, Keep going!</h2>
              </div>
              <div className="programInfo">
                <div className="programScroll">
                  <CircularProgressWithLabel value={80} />
                </div>
                <div className="programText">
                  <h4>1 active programs</h4>
                  <h5>Out of 12 total</h5>
                </div>
              </div>
            </div>
            <hr />
            <div className="viewBtn">
              <Link to="/allrecommend">
                <button>View All Active Programs</button>
              </Link>
            </div>
          </div> */}
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
        <TabPanel value={value} index={3}>
          Item Four
        </TabPanel>
        <div className="homeAllInfo">
          <div className="activeHead">
            <h2>Manage Bookings</h2>
          </div>
          <div className="manageCards">
            <div className="manageCard">
              <div className="manageText">
                <h5>Upcoming</h5>
                <i className="fa fa-angle-right"></i>
              </div>
              <div className="manageText2">
                <h5>
                  {upcominginfo?.length > 0 ? upcominginfo?.length : "No"}{" "}
                  Bookings
                </h5>
              </div>
            </div>

            <div className="manageCard">
              <div className="manageText">
                <h5>In-House</h5>
                <i className="fa fa-angle-right"></i>
              </div>
              <div className="manageText2">
                <h5>
                  {inhouseinfo?.length > 0 ? inhouseinfo?.length : "No"}{" "}
                  Bookings
                </h5>
              </div>
            </div>
            <div className="manageCard">
              <div className="manageText">
                <h5>Completed</h5>
                <i className="fa fa-angle-right"></i>
              </div>
              <div className="manageText2">
                <h5>
                  {compinfo?.length > 0 ? compinfo?.length : "No"} Bookings
                </h5>
              </div>
            </div>
          </div>

          <div className="activeHead">
            <h2>Account Status</h2>
          </div>
          <div className="walletCard">
            <div className="walletText">
              {/* <i className="fa fa-angle-right"></i> */}
            </div>
            <div className="walletText2">
              <h5>Account Status- {moneydata?.account_status}</h5>
              {/* <h5>Activate Date- {moneydata?.activate_date}</h5> */}
              <h5>Expired Date- {moneydata?.expired_date}</h5>
            </div>
            <div className="monthDrops">
              <select
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                <option hidden>Select Month</option>
                <option value={1}>1 Month</option>
                <option value={2}>2 Months</option>
                <option value={3}>3 Months (10% Off)</option>
                <option value={6}>6 Months (15% Off)</option>
                <option value={12}>12 Months (20% Off)</option>
              </select>
              <h4>
                {(month === "3" && "10% Off") ||
                  (month === "6" && "15% Off") ||
                  (month === "12" && "20% Off")}
              </h4>
            </div>
            <div className="walletInput">
              {month?.length > 0 && (
                <input
                  type="text"
                  placeholder="Amount"
                  disabled
                  value={payamount}
                />
              )}
              <button onClick={clickRech}>Recharge</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

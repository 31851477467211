import "./App.css";
import { Routes, Route } from "react-router-dom";
import SignIn from "./Components/SignIn & SignUp/SignIn/SignIn";
import SignUp from "./Components/SignIn & SignUp/SignUp/SignUp";
import SignUpDetails from "./Components/SignIn & SignUp/SignUpDetails/SignUpDetails";
import SignInOtp from "./Components/SignIn & SignUp/SignInOtp/SignInOtp";
import Home from "./Home/Home";
import Booking from "./Components/Bookings/Bookings";
import MyGopartner from "./Components/More/MyGopartner/MyGopartner";
import Kyc from "./Components/More/MyGopartner/Kyc/Kyc";
import Personal from "./Components/More/MyGopartner/Kyc/Personal/Personal";
import Gstn from "./Components/More/MyGopartner/Kyc/Gstn/Gstn";
import Bank from "./Components/More/MyGopartner/Kyc/Bank/Bank";
import Map from "./Components/More/MyGopartner/Map/Map";
import Profile from "./Components/More/Profile/Profile";
import Policy from "./Components/More/Policy/Policy";
// import Property from "./Components/More/MyGopartner/Property/Property";
// import Rooms from "./Components/More/MyGopartner/Rooms/Rooms";
// import AddBooking from "./Components/Bookings/AddBooking/AddBooking";
// import Pricing from "./Components/Pricing/Pricing";
// import Earnings from "./Components/Earnings/Earnings";
// import EarningDates from "./Components/Earnings/EarningDates/EarningDates";
// import SearchBar from "./Components/Icons/SearchBar/SearchBar";
// import Notify from "./Components/Icons/Notify/Notify";
// import AllRecommend from "./Components/AllRecommend/AllRecommend";
// import EditRoom from "./Components/More/MyGopartner/Rooms/EditRoom/EditRoom";
// import EditFloor from "./Components/More/MyGopartner/Rooms/EditFloor/EditFloor";

function App() {
  window.$loginValue = localStorage.getItem("loginData");
  window.$loginData = JSON.parse(window.$loginValue);
  // console.log(window.$loginData, "loginAllData");

  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      <Route path="/signinotp" element={<SignInOtp />}></Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signupdetails" element={<SignUpDetails />}></Route>
      <Route path="/bookings" element={<Booking />}></Route>
      {/* <Route path="/addbooking" element={<AddBooking />}></Route> */}
      {/* <Route path="/searchbar" element={<SearchBar />}></Route> */}
      {/* <Route path="/notify" element={<Notify />}></Route> */}
      {/* <Route path="/pricing" element={<Pricing />}></Route> */}
      {/* <Route path="/earnings" element={<Earnings />}></Route> */}
      {/* <Route path="/earningdates" element={<EarningDates />}></Route> */}
      {/* <Route path="/allrecommend" element={<AllRecommend />}></Route> */}
      <Route path="/mygopartner" element={<MyGopartner />}></Route>
      <Route path="/kyc" element={<Kyc />}></Route>
      <Route path="/personal" element={<Personal />}></Route>
      <Route path="/gstn" element={<Gstn />}></Route>
      <Route path="/bank" element={<Bank />}></Route>
      {/* <Route path="/property" element={<Property />}></Route> */}
      <Route path="/map" element={<Map />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      {/* <Route path="/rooms" element={<Rooms />}></Route> */}
      {/* <Route path="/editroom" element={<EditRoom />}></Route> */}
      {/* <Route path="/editfloor" element={<EditFloor />}></Route> */}
      <Route path="/policy" element={<Policy />}></Route>
    </Routes>
  );
}

export default App;

// Tabs Filter API get Data Functionality (query params in reactjs api)
// button click color change functionality
// PUT & PATCH difference
// Update API
// useRef
// useMemo

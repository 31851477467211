import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { Fetch_URL } from "../../../../../Context/Context";

export default function Personal() {
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);

  const getDetails = () => {
    axios
      .post(`${Fetch_URL}/API/owner_api.php`, {
        aksi: "proses_fetch_owner_details",
        owner_id: window.$loginData?.id,
      })
      .then((resp) => {
        console.log(resp, "resp data");
        setInfo(resp.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <div className="fourHead">
        <div className="fourInfo">
          <div
            className="backIcon"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="fourText">
            <h2>Step 1</h2>
          </div>
        </div>
        {/* <div className="fourIcon">
          <i className="fa fa-user"></i>
          <i className="fa fa-question-circle"></i>
        </div> */}
      </div>

      {info?.map((item) => {
        return (
          <>
            <div className="step1Info">
              <h3>Government ID</h3>
            </div>
            <div className="step1Input">
              <label>Government ID for verification</label>
              <input type="text" placeholder="Aadhar" disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>Name on Government ID</label>
              <input type="text" placeholder={item?.owner_name} disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>Government ID number</label>
              <input
                type="text"
                placeholder={
                  item?.owner_aadhar_number !== null
                    ? item?.owner_aadhar_number
                    : "undefined"
                }
                disabled
              />
              <i className="fa fa-check"></i>
            </div>

            <div className="step1Info">
              <h3>Personal Details</h3>
            </div>
            <div className="step1Input">
              <label>Mobile Number</label>
              <input type="text" placeholder={item?.owner_mobile} disabled />
              <i className="fa fa-check"></i>
            </div>
            <div className="step1Input">
              <label>Email Address</label>
              <input type="text" placeholder={item?.owner_email} disabled />
              <i className="fa fa-check"></i>
            </div>
          </>
        );
      })}
    </>
  );
}

/* Personal CSS */

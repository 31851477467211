import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Fetch_URL } from "../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignInOtp() {
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  // console.log(stateData, "stateData");
  const [otp, setOtp] = useState([]);

  const verifyOtp = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_login.php`, {
        aksi: "proses_verifyotpforlogin",
        mobile: stateData,
        otp: otp,
      })
      .then((resp) => {
        console.log(resp, "verify resp data");
        otp?.length > 0 && resp.data.success
          ? localStorage.setItem(
              "loginData",
              JSON.stringify(resp.data.result)
            ) || navigate("/")
          : toast.error("Wrong OTP OR User not Verified", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resendOtp = () => {
    axios
      .post(`${Fetch_URL}/API/hotel_login.php`, {
        aksi: "proses_loginwithotp",
        mobile: stateData,
      })
      .then((resp) => {
        console.log(resp, "resendOtp resp data");
        resp?.data?.success &&
          toast.success("OTP has been sent", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="loginCont">
        <div className="loginHead">
          <div className="loginText">
            <h2>CO PAYGOO</h2>
          </div>
          <div className="lang">
            <i className="fa fa-globe"></i>
            <h5>English</h5>
          </div>
        </div>
        <div className="loginBottom">
          <div className="otpInfo">
            <h2>Welcome to CO PAYGOO</h2>
            <h4>
              You will receive the OTP if phone number is registered with our
              systems
            </h4>
          </div>
          <div className="otpData">
            <h4>+91{stateData}</h4>
            {/* <h5>Edit</h5> */}
          </div>
          <div className="loginBox">
            <div className="otpInput">
              <input
                type="number"
                placeholder="Enter OTP"
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
            </div>
            <div className="passLink" onClick={resendOtp}>
              <h5>Resend Code</h5>
            </div>
          </div>
          <div className="loginBtn" onClick={verifyOtp}>
            <button>Login</button>
          </div>
          <div className="loginTerms">
            <h6>
              By logging into the account you are agreeing with our
              <span> Terms of use </span>and <span>Privacy policy</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

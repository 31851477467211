import axios from "axios";
import { useEffect, useState } from "react";
import { Fetch_URL } from "../../../Context/Context";
// mui
import { ColorRing } from "react-loader-spinner";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function InhouseBook() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (item) => (event, isExpanded) => {
    setExpanded(isExpanded ? item : false);
  };

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [checkedout, setCheckedout] = useState(false);

  const Inhouse = () => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_fetchbookingbyStatus",
        booking_status: "Inhouse",
        hotel_id: window.$loginData?.hotel_id,
      })
      .then((resp) => {
        console.log(resp, "inhouse booking resp data");
        setData(resp.data.result);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkoutStatus = (Id) => {
    axios
      .post(`${Fetch_URL}/API/booking_api.php`, {
        aksi: "proses_chnagebookingstatus",
        booking_status: "Completed",
        b_id: Id,
      })
      .then((resp) => {
        console.log(resp, "checkout resp data");
        resp.data.success && setCheckedout(resp.data.success);
        toast.success("Checked-Out", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        Inhouse();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Inhouse();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="bookDataCont">
        {loader ? (
          <>
            <div className="loader">
              <ColorRing
                visible={true}
                height="100"
                width="100"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["green", "green", "green", "green", "green"]}
              />
            </div>
          </>
        ) : (
          <>
            {data?.length === 0 || data === null ? (
              <>
                <div className="inhouseImg">
                  <img
                    src="woman-looking-monthly-calendar-menstruation-260nw-1687894090.webp"
                    alt=""
                  />
                  <h2>No booking, yet!</h2>
                  <h4>You don't have any Inhouse booking right now.</h4>
                </div>
              </>
            ) : (
              <>
                <div className="upcomingHead">
                  <h4>Check-In ({data?.length})</h4>
                </div>
                {data?.map((item) => {
                  return (
                    <>
                      <Accordion
                        expanded={expanded === item}
                        onChange={handleChange(item)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography sx={{ width: "100%", flexShrink: 0 }}>
                            {item.user_name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <div className="bookDetail">
                              <div className="bookText">
                                <h4>{item.user_name}</h4>
                                <h4>
                                  <i className="fa fa-rupee"></i>
                                  {item.amount}
                                </h4>
                              </div>
                              <div className="bookId">
                                <h5>#{item.booking_id}</h5>
                                <h5>- {item.booking_date}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Mobile-No:-</h5>
                                <h5>{item.user_mobile}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Check-In:-</h5>
                                <h5>{item.check_id}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Check-In Time:-</h5>
                                <h5>{item.checkintime}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Paid Amount:-</h5>
                                <h5>{item.razr_amt}</h5>
                              </div>
                              <div className="bookId">
                                <h5>Rest Amount (Pay at Hotel):-</h5>
                                <h5>{item.restamount}</h5>
                              </div>
                              <div className="bookText">
                                <div className="bookId">
                                  <h5>Check-Out:-</h5>
                                  <h5>{item.check_out}</h5>
                                </div>
                                <div className="bookBtn2">
                                  <button
                                    onClick={() => {
                                      checkoutStatus(item.id);
                                    }}
                                  >
                                    {checkedout ? "Checked-Out" : "Check-Out"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

/* InhouseBook CSS */

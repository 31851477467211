import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Fetch_URL } from "../../../Context/Context";
// mui
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);
  const [hide, setHide] = useState(false);

  const login = () => {
    email?.length > 0 && password?.length > 0
      ? axios
          .post(`${Fetch_URL}/API/hotel_login.php`, {
            aksi: "proses_login",
            email: email,
            password: password,
          })
          .then((resp) => {
            console.log(resp, "login resp data");
            if (resp?.data?.success === true) {
              localStorage.setItem(
                "loginData",
                JSON.stringify(resp?.data?.result)
              );
              // toast.success("Login Successfully", {
              //   position: toast.POSITION.BOTTOM_LEFT,
              // }) &&
              // setTimeout(() => {
              navigate("/");
              // }, 1000)
            } else {
              toast.error("Wrong Credentials OR User not Verified", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Please Fill All Details", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  const loginOtp = () => {
    email?.length === 10
      ? axios
          .post(`${Fetch_URL}/API/hotel_login.php`, {
            aksi: "proses_loginwithotp",
            mobile: email,
          })
          .then((resp) => {
            console.log(resp, "otpLogin resp data");
            resp?.data?.success
              ? toast.success("OTP has been sent", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) &&
                setTimeout(() => {
                  navigate("/signinotp", { state: email });
                }, 800)
              : toast.error("Mobile Number not valid", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Wrong Mobile Number", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  // const forgot = () => {
  // axios
  //   .post(``, {})
  //   .then((resp) => {
  //     console.log(resp, "forgot resp data");
  //     toast.success("OTP sent", {
  //       position: toast.POSITION.BOTTOM_LEFT,
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // };

  return (
    <>
      <ToastContainer />
      <div className="loginCont">
        <div className="loginHead">
          <div className="loginText">
            <h2>
              <i>
                PAYGOO <span>PARTNER</span>
              </i>
            </h2>
          </div>
          <div className="lang">
            <i className="fa fa-globe"></i>
            <h5>English</h5>
          </div>
        </div>
        <div className="loginBottom">
          <div className="loginInfo">
            <h2>Welcome to PAYGOO PARTNER</h2>
            <h4>
              Enter the registered email address or phone number associated with
              us
            </h4>
          </div>
          <div className="loginBox">
            <div className="loginData">
              <label>Email ID</label>
              <div className="loginInput">
                <input
                  type="text"
                  placeholder="Enter Email ID"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="loginData">
              <label>Password</label>
              <div className="loginInput">
                <input
                  type={hide ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                />
                <div
                  className="hideIcon"
                  onClick={() => {
                    setHide(true);
                  }}
                >
                  <RemoveRedEyeIcon />
                </div>
              </div>
            </div>
            {/* <div className="passLink" onClick={forgot}>
              <h5>Forgot Password</h5>
            </div> */}
          </div>
          <div className="signupBtn" onClick={login}>
            <button>Sign In</button>
          </div>
          <div className="passLink" onClick={loginOtp}>
            {email.length > 4 && !isNaN(email) ? (
              <>
                <h5>Login With OTP</h5>
              </>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="signupTerms">
            <Link to="/signup">
              <h4>
                Didn't have account?<span> SignUp</span>
              </h4>
            </Link>
          </div> */}

          <div className="loginTerms">
            <h6>
              By logging into the account you are agreeing with our
              <span> Terms of use </span>and <span>Privacy policy</span>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

import { Link } from "react-router-dom";
import BottomNav from "../../BottomNavigate/BottomNav";

export default function MyGopartner() {
  return (
    <>
      <BottomNav />
      <div className="navText">
        <h2>Property Details</h2>
      </div>
      <div className="myGotext">
        <h3>Your PAYGOO Property</h3>
      </div>
      <Link to="/kyc">
        <div className="myGocard">
          <div className="myGoinfo">
            <i className="fa fa-credit-card"></i>
            <h3>KYC</h3>
            <i className="fa fa-angle-right"></i>
          </div>
          <div className="myGodetail">
            <h5>
              Please share your national ID, business ID & Banking details.
              Mandatory as per government regulations.
            </h5>
          </div>
        </div>
      </Link>
      {/* <Link to="/property">
        <div className="myGocard">
          <div className="myGoinfo">
            <i className="fa fa-image"></i>
            <h3>Properly Photos</h3>
            <i className="fa fa-angle-right"></i>
          </div>
          <div className="myGodetail">
            <h5>
              Photos of Entrance, Reception, Lobby, Rooms & Washrooms to help
              guests visualise your property
            </h5>
          </div>
        </div>
      </Link> */}
      {/* <Link to="/rooms">
        <div className="myGocard">
          <div className="myGoinfo">
            <i className="fa fa-building"></i>
            <h3>Rooms</h3>
            <i className="fa fa-angle-right"></i>
          </div>
          <div className="myGodetail">
            <h5>
              Add or modify room numbers, floor numbers & room categories of
              your property
            </h5>
          </div>
        </div>
      </Link> */}
      <Link to="/map">
        <div className="myGocard">
          <div className="myGoinfo">
            <i className="fa fa-user"></i>
            <h3>General Info</h3>
            <i className="fa fa-angle-right"></i>
          </div>
          <div className="myGodetail">
            <h5>
              View your Property name, location on map, address & landmark
            </h5>
          </div>
        </div>
      </Link>
    </>
  );
}

/* MyGopartner CSS */
